import Str from '@/helpers/Str';

export const validateEmail = (rule, value, callback) => {
  if (value === undefined || value === null || Str.empty(value)) {
    callback(new Error('Необходимо заполнить'));
    return;
  } else if (!Str.email(value)) {
    callback(new Error('Некорректный E-mail адрес'));
  }

  callback();
};

export const validatePhone = (rule, value, callback) => {
  if (value === undefined || value === null || Str.empty(value)) {
    callback(new Error('Необходимо заполнить'));
    return;
  } else if (Str.phoneNotCorrect(value)) {
    callback(new Error('Некорректный номер'));
  }

  callback();
};