<template>
  <div class="up-new-customer">
    <h1 class="up-main-title">Добавление нового клиента</h1>
    <el-form :rules="rules" :model="form" ref="form">
      <el-form-item label="ФИО или название организации:" prop="userName">
        <el-input
          v-model="form.userName"
          placeholder="ФИО или название организации"
        />
      </el-form-item>
      <el-form-item label="Почта клиента:" prop="email">
        <el-input v-model="form.email" placeholder="Почта клиента" />
      </el-form-item>
      <el-form-item label="Телефон клиента:" prop="phone">
        <el-input v-model="form.phone" placeholder="Телефон клиента" />
      </el-form-item>
      <el-alert class="up-new-customer__alert" type="warning" :closable="false">
        По данному номеру телефона клиент будет зарегистрирован на платформе
      </el-alert>
      <el-button type="primary" @click="addClient" v-loading="loading">
        Добавить клиента
      </el-button>
    </el-form>
  </div>
</template>

<script>
import { validateEmail, validatePhone } from "@/utils/validations";

import validatableFormMixin from "@/mixins/validatableForm.mixin";

import { mapActions } from "vuex";

const requiredValidation = [
  { required: true, message: "Необходимо заполнить", trigger: "blur" },
];

export default {
  name: "NewManagerClient",
  mixins: [validatableFormMixin],
  data: () => ({
    form: {
      userName: "",
      email: "",
      phone: "",
    },
    loading: false,
    rules: {
      userName: requiredValidation,
      phone: [{ validator: validatePhone, trigger: "blur", required: true }],
      email: [{ validator: validateEmail, trigger: "blur", required: true }],
    },
  }),
  methods: {
    ...mapActions(["setNavigationText"]),
    async addClient() {
      if (!(await this.isValid()) || this.loading) return;

      this.loading = true;

      try {
        await this.$axios.post("/manager/customer", this.form);

        this.form = {
          userName: "",
          email: "",
          phone: "",
        };

        this.$message({
          message: "Поздравляем! Клиент успешно добавлен",
          type: "success",
        });
      } catch (e) {
        //
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.setNavigationText("Добавление нового клиента");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/manager/customers/new.scss";
</style>